import { useState } from 'react';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  Checkbox,
  Message,
  Typography,
  WarningIcon,
} from '@la/ds-ui-components';
import { Alert } from '@la/shared-components/src/components/Alert/Alert';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { addPlaidBankAccount } from 'lib/plaid/plaid';
import { getSiteId, getSiteName } from 'redux/coreSlice';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import checkoutStyles from '../Checkout.module.scss';
import { PaymentMethod, SingleUseCard } from '../Checkout.types';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import { PaymentMethodBadgeGroup } from '../PaymentMethodBadge/PaymentMethodBadgeGroup';
import UseAccountModal from '../UseAccountModal/UseAccountModal';
import UseCardModal from '../UseCardModal/UseCardModal';
import styles from './PaymentMethodCard.module.scss';
import * as S from './PaymentMethodCard.styles';

export type ModalName = '' | 'useAccount' | 'useCard' | 'editPaymentMethod';

export type PaymentModalsProps = {
  closeModal: () => void;
  hasBackButton: boolean;
  modalName: ModalName;
  openEditPaymentModal: () => void;
};

export function isSingleUseCard(
  paymentMethod: PaymentMethod | null
): paymentMethod is SingleUseCard {
  return (
    !!paymentMethod &&
    (paymentMethod as SingleUseCard).cardType === 'SINGLE_USE'
  );
}

/* PaymentMethodCard*/
export default function PaymentMethodCard() {
  const siteId = useAppSelector(getSiteId);
  const siteName = useAppSelector(getSiteName);
  const { data: userId } = useGetUserIdQuery(siteId);
  const {
    checkoutErrorsAreVisible,
    checkoutFieldErrorStates,
    handleHasAgreedToAutopayUpdate,
    hasAgreedToAutopay,
    hasAutopayPaymentOptionSelected,
    selectedPaymentMethod,
    storedPaymentMethods,
  } = useConsolidatedCheckout();
  const hasPaymentMethod = !!selectedPaymentMethod;
  const [openModalName, setOpenModalName] = useState<ModalName>('');

  const { cartCheckoutPaymentTerms } = useFlags();

  function openEditPaymentModal() {
    setOpenModalName('editPaymentMethod');
  }

  function openCardModal() {
    setOpenModalName('useCard');
  }

  function closeModal() {
    setOpenModalName('');
  }

  const isShowingErrorMessage =
    checkoutFieldErrorStates.hasPaymentMethodError && checkoutErrorsAreVisible;

  let paymentMethodError = CHECKOUT_ERRORS.paymentMethod;
  if (cartCheckoutPaymentTerms) {
    if (selectedPaymentMethod && hasAutopayPaymentOptionSelected) {
      const { paymentMethodId } = selectedPaymentMethod;
      if (isSingleUseCard(selectedPaymentMethod) && hasAgreedToAutopay) {
        paymentMethodError = CHECKOUT_ERRORS.storePaymentMethod;
      } else if (paymentMethodId && !hasAgreedToAutopay) {
        paymentMethodError = CHECKOUT_ERRORS.autopay;
      } else {
        paymentMethodError = CHECKOUT_ERRORS.storePaymentMethodAndAutopay;
      }
    } else if (hasAutopayPaymentOptionSelected) {
      paymentMethodError = CHECKOUT_ERRORS.paymentMethodWithAutopay;
    }
  }

  return (
    <>
      <p className={checkoutStyles.sectionHead}>Select payment method</p>
      <div className={styles.paymentMethodCard}>
        <p className={styles.informationalCopy}>
          Bank accounts are stored automatically.
        </p>
        {isSingleUseCard(selectedPaymentMethod) &&
        hasAutopayPaymentOptionSelected ? (
          <S.AutopayPaymentMethodWarning>
            <Alert
              icon={<WarningIcon variant="bold" />}
              message="You have opted to use a payment option that requires a stored payment method. Please edit your current payment method."
            />
          </S.AutopayPaymentMethodWarning>
        ) : null}
        <PaymentMethodBadgeGroup />
        <div className={styles.actionButtons}>
          <Button
            rightIcon={<ArrowSquareOut weight="bold" />}
            size="medium"
            onClick={() => addPlaidBankAccount(userId, siteId)}
          >
            {storedPaymentMethods?.storedBankAccounts
              ? 'Replace bank account'
              : 'Add bank account'}
          </Button>
          <Button size="medium" onClick={openCardModal}>
            {storedPaymentMethods?.storedCreditCards
              ? 'Use another Card'
              : 'Add a Card'}
          </Button>
        </div>
        {cartCheckoutPaymentTerms && hasAutopayPaymentOptionSelected ? (
          <S.AutopayContainer>
            <Checkbox
              ariaLabel="agree to autopay"
              disabled={
                !hasPaymentMethod || isSingleUseCard(selectedPaymentMethod)
              }
              id="autopay-agreement"
              label="Agree to autopay"
              size="large"
              onCheckedChange={handleHasAgreedToAutopayUpdate}
              required={hasAutopayPaymentOptionSelected}
            />
            <S.AutopayDisclaimer>
              <Typography size="medium" variant="ui">
                Your stored payment method will be charged for payments that
                will occur automatically on specific dates. By submitting your
                payment information, you authorize {siteName} to automatically
                charge and store this payment method.
              </Typography>
            </S.AutopayDisclaimer>
          </S.AutopayContainer>
        ) : null}
      </div>

      {isShowingErrorMessage ? (
        <Message messageType="error">{paymentMethodError}</Message>
      ) : null}
      <PaymentModals
        closeModal={closeModal}
        hasBackButton={hasPaymentMethod}
        modalName={openModalName}
        openEditPaymentModal={openEditPaymentModal}
      />
    </>
  );
}
/* */

/* PaymentModals */
function PaymentModals({
  closeModal,
  hasBackButton,
  modalName,
  openEditPaymentModal,
}: PaymentModalsProps) {
  switch (modalName) {
    case 'useCard':
      return (
        <UseCardModal
          closeModal={closeModal}
          hasBackButton={hasBackButton}
          modalTitle="Payment method"
          openEditPaymentModal={openEditPaymentModal}
        />
      );
    case 'useAccount':
      return (
        <UseAccountModal closeModal={closeModal} modalTitle="Payment method" />
      );
    default:
      return null;
  }
}
