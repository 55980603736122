import { useEffect } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Loader } from '@la/ds-ui-components';
import { getLAHostnameParts } from '@la/utilities';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { useLazyGetProgramQuery } from 'redux/services/activity';
import { useGetPaymentWaiverQuery } from 'redux/services/checkoutApi';
import { useLazyGetRegistrationIdsQuery } from 'redux/services/registrationApi';
import styles from '../Checkout.module.scss';
import CheckoutPageAlerts from '../CheckoutPageAlert/CheckoutPageAlerts';
import CheckoutRollup from '../CheckoutRollup/CheckoutRollup';
import OrderSummaryCards from '../OrderSummaryCards/OrderSummaryCards';
import PaymentMethodCard from '../PaymentMethodCard/PaymentMethodCard';
import TermsAndConditionsAgreementBox from '../TermsAndConditionsAgreementBox/TermsAndConditionsAgreementBox';
import EmptyCartNotice from './EmptyCartNotice';

/* Checkout Page Content */
export default function CheckoutPageContent({
  error,
  siteId,
  userId,
}: Readonly<{
  error?: FetchBaseQueryError | SerializedError;
  siteId: string | null;
  userId?: number | null;
}>) {
  const { subdomain } = getLAHostnameParts();
  const { cartItems, isLoading, handlePaymentWaiverUpdate, paymentWaiver } =
    useConsolidatedCheckout();
  const { registrationAnalyticsData, updateRegistrationAnalyticsData } =
    useCheckoutInfo();

  const { data: paymentWaiverData } = useGetPaymentWaiverQuery({ siteId });

  useEffect(() => {
    if (paymentWaiverData?.waiverId !== paymentWaiver?.waiverId) {
      handlePaymentWaiverUpdate(paymentWaiverData);
    }
  }, [handlePaymentWaiverUpdate, paymentWaiver?.waiverId, paymentWaiverData]);

  const [getProgram] = useLazyGetProgramQuery();
  const [getRegistrationIds] = useLazyGetRegistrationIdsQuery();
  /**
   * Update the context with the analytics data needed to
   * render on the confirmation page.
   */
  useEffect(() => {
    if (cartItems && siteId && userId) {
      Promise.all(
        cartItems.flatMap(async (item) => {
          const registrations =
            item.subprograms && item.subprograms.length > 0
              ? await Promise.all(
                  item.subprograms.map(async (subprogram) => {
                    const { data: subprogramData } = await getProgram({
                      siteId,
                      programId: subprogram.subprogramId.toString(),
                    });

                    const registrationIds = await getRegistrationIds({
                      siteDomain: subdomain,
                      registrationIds: subprogram.registrations.map((r) =>
                        r.registrationId.toString()
                      ),
                    });

                    return Promise.all(
                      subprogram.registrations.map((registration) => ({
                        programFullName: item.cartItemName,
                        programId: subprogram.subprogramId,
                        programSport: subprogramData?.details?.sport,
                        programType: item.type?.toUpperCase(),
                        registrationUserId: userId.toString(),
                        registrationId:
                          registrationIds.data?.[
                            registration.registrationId.toString()
                          ].registrationIdOg.toString(),
                      }))
                    );
                  })
                )
              : [];

          return registrations.flat();
        })
      ).then((data) => {
        const newData = data.flat();
        if (
          JSON.stringify(registrationAnalyticsData) !== JSON.stringify(newData)
        ) {
          updateRegistrationAnalyticsData(newData);
        }
      });
    }
  }, [
    getProgram,
    getRegistrationIds,
    siteId,
    subdomain,
    userId,
    registrationAnalyticsData,
    updateRegistrationAnalyticsData,
    cartItems,
  ]);

  if (isLoading.includes('page')) {
    return (
      <Loader
        loading={true}
        isFullscreen={true}
        title=""
        description="One moment please while your cart information loads."
      />
    );
  }

  return cartItems && cartItems.length > 0 ? (
    <ColumnGrid>
      <CheckoutPageAlerts />
      <div className={styles.majorColumn}>
        <OrderSummaryCards cartItems={cartItems} />
        {paymentWaiver ? (
          <TermsAndConditionsAgreementBox
            policyContent={paymentWaiver.content}
            policyName={paymentWaiver.name}
          />
        ) : null}
        <PaymentMethodCard />
      </div>
      <div className={styles.minorColumn}>
        <CheckoutRollup />
      </div>
    </ColumnGrid>
  ) : (
    <EmptyCartNotice />
  );
}
/* */
