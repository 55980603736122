import clsx from 'clsx';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { capitalizeFirstLetter } from 'lib/utils/stringUtils';
import {
  PaymentMethod,
  SingleUseCard,
  StoredBankAccount,
  StoredCreditCard,
} from '../Checkout.types';
import styles from './PaymentMethodBadge.module.scss';
import {
  paymentMethodBadgeConfig,
  PaymentMethodDetailConfig,
} from './PaymentMethodBadgeConfig';

export type PaymentMethodBadgeProps = {
  isSelectedPaymentMethod: boolean;
  paymentMethod: PaymentMethod;
  paymentMethodType: 'stored' | 'temporary';
};

export function isBankAccount(
  storedType: PaymentMethod
): storedType is StoredBankAccount {
  return (storedType as StoredBankAccount).paymentType === 'ACH';
}

export function isStoredCard(
  storedType: PaymentMethod
): storedType is StoredCreditCard {
  return (
    (storedType as StoredCreditCard).paymentType === 'CARD' &&
    (storedType as StoredCreditCard).cardType === 'STORED'
  );
}

export function isTemporaryCard(
  storedType: PaymentMethod
): storedType is SingleUseCard {
  return (
    (storedType as SingleUseCard).paymentType === 'CARD' &&
    (storedType as SingleUseCard).cardType === 'SINGLE_USE'
  );
}

/* PaymentMethodBadge */
export default function PaymentMethodBadge({
  isSelectedPaymentMethod,
  paymentMethod,
  paymentMethodType,
}: PaymentMethodBadgeProps) {
  const { handlePaymentMethodSelection } = useConsolidatedCheckout();
  let paymentMethodDetailConfig: PaymentMethodDetailConfig;

  if (!paymentMethod) {
    return null;
  }

  function getPaymentMethodNetwork(paymentMethod: PaymentMethod) {
    if (isStoredCard(paymentMethod)) {
      return paymentMethod.paymentNetwork;
    } else if (isTemporaryCard(paymentMethod)) {
      return paymentMethod.cardBrand;
    } else {
      return 'Visa';
    }
  }

  const isPaymentMethodBank = isBankAccount(paymentMethod);
  if (isPaymentMethodBank) {
    paymentMethodDetailConfig = paymentMethodBadgeConfig.bank;
  } else {
    const network = getPaymentMethodNetwork(paymentMethod);
    paymentMethodDetailConfig =
      paymentMethodBadgeConfig.creditCard[
        network as keyof typeof paymentMethodBadgeConfig.creditCard
      ];
  }

  const setSelectedPaymentMethod = () => {
    if (!isSelectedPaymentMethod) {
      handlePaymentMethodSelection(paymentMethod);
    }
  };

  function generateAccountInfoText(paymentMethod: PaymentMethod) {
    const accountType = isBankAccount(paymentMethod)
      ? capitalizeFirstLetter(paymentMethod.accountType)
      : 'Card';
    const last4 = isTemporaryCard(paymentMethod)
      ? paymentMethod.cardLast4
      : paymentMethod!.last4Digits;
    return `${accountType} ending in ${last4}`;
  }

  const badgeClassName = clsx(
    styles.paymentMethodBadge,
    paymentMethodType === 'temporary' && styles.additionalPaymentMethod
  );

  return (
    <div
      className={badgeClassName}
      data-testid="payment_badge"
      onClick={setSelectedPaymentMethod}
    >
      <RadioButton
        checked={isSelectedPaymentMethod}
        iconColor={'var(--blue-grey-600)'}
      />
      {paymentMethodDetailConfig ? (
        <img
          src={paymentMethodDetailConfig.logo}
          alt={paymentMethodDetailConfig.name}
        />
      ) : null}
      <div className={styles.accountInfo}>
        {generateAccountInfoText(paymentMethod)}
      </div>
    </div>
  );
}
/* */
