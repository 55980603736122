import { useState, KeyboardEvent } from 'react';
import { Checkbox, Message } from '@la/ds-ui-components';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import PaymentPolicyModal from '../PaymentPolicyModal/PaymentPolicyModal';
import styles from './TermsAndConditionsAgreementBox.module.scss';

export type TermsAndConditionsAgreementBoxProps = {
  policyContent?: string;
  policyName?: string;
};

/* TermsAndConditionsAgreementBox */
export default function TermsAndConditionsAgreementBox({
  policyContent,
  policyName,
}: TermsAndConditionsAgreementBoxProps) {
  const {
    checkoutErrorsAreVisible,
    checkoutFieldErrorStates,
    handleHasAgreedToPaymentPolicy,
    hasAgreedToPaymentPolicy,
  } = useConsolidatedCheckout();
  const [isOpenPaymentPolicyModal, setIsOpenPaymentPolicyModal] =
    useState<boolean>(false);

  function openPolicyModal() {
    setIsOpenPaymentPolicyModal(true);
  }

  function closePolicyModal() {
    setIsOpenPaymentPolicyModal(false);
  }

  function handleKeyDownTermsModal(
    evt: KeyboardEvent<HTMLButtonElement>
  ): void {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      openPolicyModal();
    }
  }

  const isShowingErrorMessage =
    checkoutFieldErrorStates.hasPaymentPolicyError && checkoutErrorsAreVisible;

  return (
    <>
      <div className={styles.termsAndConditionsAgreementBoxContainer}>
        <div className={styles.termsAndConditionsAgreementBox}>
          <Checkbox
            ariaLabel="I have read and agree to all Payment and Refund Policies"
            checked={hasAgreedToPaymentPolicy}
            id="terms-condition-agreement"
            onCheckedChange={(checked) =>
              handleHasAgreedToPaymentPolicy(checked)
            }
            size="large"
          />
          <div className={styles.label}>
            I have read and agree to all{' '}
            <span
              role="button"
              tabIndex={0}
              onClick={openPolicyModal}
              onKeyDown={handleKeyDownTermsModal}
            >
              Payment and Refund Policies
            </span>
          </div>
        </div>
        {isShowingErrorMessage ? (
          <Message messageType="error">{CHECKOUT_ERRORS.paymentTerms}</Message>
        ) : null}
      </div>
      <PaymentPolicyModal
        closeModal={closePolicyModal}
        modalTitle="Payment policies"
        open={isOpenPaymentPolicyModal}
        policyContent={policyContent}
        policyName={policyName}
      />
    </>
  );
}
/* */
